<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Discount</h4>
      </div>
      <div class="col-md-3 col-xl-6">
        <router-link to="/discount/create">
          <button
            class="btn btn-primary rounded-pill width-lg float-right aligncenter"
          >
            <feather type="file-plus"></feather>Create
          </button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <Search :types="search_types" @onSearch="onSearch($event)">
            </Search>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">image</th>
                    <th scope="col">Code</th>
                    <th scope="col">Description</th>
                    <th scope="col">Type</th>
                    <th scope="col" class="tf-3">Update At</th>
                    <!-- <th scope="col">Status</th> -->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in data" :key="index">
                    <td scope="row">{{
                      page === 1
                        ? page - 1 + index + 1
                        : (page - 1) * 10 + (index + 1)
                    }}</td>
                    <td scope="row">
                      <img
                        v-if="item.image"
                        style="width:340px;height:195px;"
                        :src="`${baseImageUrl}/${item.image}`"
                        class="img-fluid"
                      />
                    </td>
                    <td scope="row">
                      {{ item.discount }}
                    </td>

                    <td scope="row" style="max-width: 150px;">
                      {{ item.description }}
                    </td>
                    <td scope="row" style="max-width: 150px;">
                      {{ item.type }}
                    </td>
                    <td scope="row" style="max-width: 150px;">{{
                      item.updatedAt.substring(0, 10)
                    }}</td>

                    <td class="d-flex">
                      <button
                        class="btn btn-warning p-0 px-2 py-1 mr-2"
                        @click="handleClickEditDiscount(item)"
                        >Edit</button
                      >

                      <b-button
                        v-b-modal.modal-center
                        class="btn btn-danger p-0 px-2 py-1"
                        @click="handleClickDeleteDiscount(item._id)"
                        >Delete</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Pagination
              :data="pagination"
              :page="page"
              @onPage="onPage($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <b-modal v-model="modalShow" size="lg" title="sub-title" @ok="handleOk"
      ><b-form-textarea
        id="textarea"
        v-model="sub_title"
        rows="3"
        max-rows="6"
      ></b-form-textarea
    ></b-modal> -->
  </Layout>
</template>

<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
// import PageHeader from '@components/page-header'
import axios from '@/axios'
import Pagination from '@src/components/base/pagination'
import Search from '@src/components/base/search'
import { BASE_IMAGE_URL } from '@src/config/configs'
import moment from 'moment'
/**
 * Contents component
 */
export default {
  page: {
    title: 'Discount',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, Pagination, Search },
  data() {
    return {
      sub_title: '',
      modalShow: false,
      baseImageUrl: BASE_IMAGE_URL,
      title: 'Discount',
      search_types: [
        { key: 'discount', value: 'Discount' },
        { key: 'description', value: 'Description' },
      ],
      sortByList: [
        { key: 'Video', value: true },
        { key: 'Content', value: false },
      ],
      sortByType: [
        { key: 'Free', value: false },
        { key: 'Paid', value: true },
      ],
      search: {},
      page: 1,
      data: [],
      pagination: {},
      sort_by: '',
      sort_type: '',
    }
  },
  mounted() {
    this.onLoad()
  },

  methods: {
    onPage(page) {
      this.page = page
      this.onLoad()
    },
    onSearch(search) {
      this.search = search
      this.page = 1
      this.onLoad()
    },
    async onLoad() {
      await axios
        .get('/api/discount', {
          params: {
            page: this.page,
            ...this.search,
            is_video: this.sort_by,
            is_paid: this.sort_type,
            orderByField: 'createdAt',
            orderBy: 'desc',
            type: 'free',
            size: 10,
          },
        })
        .then((res) => {
          this.data = res.data
          this.pagination = res.data
        })
        .catch((err) => {
          alert(err.response.data.message)
        })
    },
    async handleClickDeleteDiscount(id) {
      var confirmDel = confirm('Are you sure you want to delete?')

      if (confirmDel) {
        await axios.delete(`/api/discount/${id}`).then((result) => {
          this.onLoad()
        })
      }
    },
    async handleClickEditDiscount(discount) {
      this.$router.push({
        path: '/discount/create',
        query: { id: discount._id },
      })
    },
    async searchByHashtag(x) {
      this.search = {
        hashtag: x._id,
      }
      this.onLoad()
    },
  },
}
</script>
